import { Component, Vue, Watch } from 'vue-property-decorator';
import MiSettings from '@/components/MiSettings/MiSettings.vue';

@Component<Settings>({
  components: {
    MiSettings,
  },
})
export default class Settings extends Vue {
  public $pageTitle = 'Instellingen';

  protected menu = false;

  protected visibleSettings: tcmgSetting[] = [
    {
      key: 'hours_change_lock_until_users',
      value: '',
      label: 'Wijzigen uren voor gebruikers blokkeren t/m',
      description: 'GEBRUIKERS kunnen alle uren vóór deze datum niet meer aanpassen',
      type: 'date',
      visible: true,
    },
    {
      key: 'hours_change_lock_until_admins',
      value: '',
      label: 'Wijzigen uren voor beheerders blokkeren t/m',
      description: 'BEHEERDERS kunnen alle uren vóór deze datum niet meer aanpassen',
      type: 'date',
      visible: true,
    },
    {
      key: 'invoice_sender_name',
      value: '',
      label: 'Afzender Naam facturatie',
      description: 'Naam van de afzender vanuit waar de facturen verstuurd worden',
      type: 'text-field',
      visible: true,
    },
    {
      key: 'invoice_sender_email',
      value: '',
      label: 'Afzender E-mailadres facturatie',
      description: 'Het afzender adres vanuit waar de facturen verstuurd worden',
      type: 'text-field',
      visible: true,
    },
  ];

  protected date: string | null = null;

  protected requested_planning_notifiable = false;

  public mounted() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          {
            name: 'Instellingen',
            path: '/instellingen',
          },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface tcmgSetting {
  key: string;
  type: string;
  value: any;
  label: string;
  description?: string;
  menu?: boolean;
  visible?: boolean;
}
